.showActions {
  opacity: 0;
  transition: ease-in-out 400ms;
}

.productCard {
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 0px;
  cursor: default;
  min-width: 240px;
}

.productCard:hover .showActions {
  opacity: 1;
  transition: ease-in-out 400ms;
}

.productCard:hover {
  border-radius: 0px;
}
